function variantWhite() {
  return {
    bg: "#FFFFFF",
    color: "#221D10",
  };
}

function variantCream() {
  return {
    bg: "cream",
    color: "#221D10",
  };
}

function variantBlack() {
  return {
    bg: "gray.900",
    color: "#FFFFFF",
  };
}

function variantGreen() {
  return {
    bg: "olive",
    color: "#FFFFFF",
  };
}

const variants = {
  white: variantWhite,
  cream: variantCream,
  black: variantBlack,
  green: variantGreen,
};

export const Footer = { variants };
