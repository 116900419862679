import type { ComponentMultiStyleConfig, SystemStyleObject } from "@chakra-ui/react";

const parts = ["button", "divider", "label", "icon"];

const baseStyle: SystemStyleObject = {
  button: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "1.2",
    borderRadius: "base",
    fontWeight: "medium",
    transitionProperty: "common",
    transitionDuration: "normal",
    _focus: {
      boxShadow: "outline",
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
      boxShadow: "none",
    },
    _hover: {
      _disabled: {
        bg: "initial",
      },
    },
  },
  divider: {
    w: "1px",
    py: 3,
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
};

const sizes = {
  md: {
    button: { h: 10 },
    label: { px: 4, h: 10 },
    icon: { display: "flex", placeContent: "center", w: 10, fontSize: "16px" },
  },
  lg: {
    button: { h: 12 },
    label: { px: 6, h: 12 },
    icon: { display: "flex", placeContent: "center", w: 12, fontSize: "20px" },
  },
};

function variantPrimary() {
  const { bg = "almond", color = "white", hoverBg = "#BE642F", activeBg = "#BE642F" } = {};

  const style = {
    bg,
    color,
    _hover: { bg: hoverBg },
    _active: { bg: activeBg },
  };

  return {
    button: style,
    divider: { bg: "whiteAlpha.400" },
  };
}

function variantTransparent() {
  const {
    bg = "rgba(255, 255, 255, 0.2)",
    color = "white",
    hoverBg = "rgba(255, 255, 255, 0.25)",
    activeBg = "rgba(255, 255, 255, 0.25)",
  } = {};

  const style = {
    bg,
    color,
    _hover: { bg: hoverBg },
    _active: { bg: activeBg },
  };

  return {
    button: style,
    divider: { bg: "whiteAlpha.400" },
  };
}

function variantWhite() {
  const { bg = "white", color = "gray.900", hoverBg = "#F4F4F3", activeBg = "#F4F4F3" } = {};

  const style = {
    bg,
    color,
    _hover: { bg: hoverBg },
    _active: { bg: activeBg },
  };

  return {
    button: style,
    divider: { bg: "gray.200" },
  };
}

const variants = {
  primary: variantPrimary,
  transparent: variantTransparent,
  white: variantWhite,
};

const defaultProps = {
  variant: "primary",
  size: "md",
  colorScheme: "orange",
};

export const DropdownButton: ComponentMultiStyleConfig = { parts, baseStyle, defaultProps, variants, sizes };
