import * as prismic from "@prismicio/client";
import { enableAutoPreviews } from "@prismicio/next";
import Link from "next/link";
import smConfig from "./slicemachine.config.json";

if (!smConfig.apiEndpoint) {
  console.warn(
    "Looks like Slice Machine hasn't been bootstraped already.\nCheck the `Getting Started` section of the README file :)",
  );
}

export const PAGE_LINKS = [
  "app.title",
  "app.description",
  "app.image",
  "app.features",
  "logo-cloud.logos",
  "pricing-tier.description",
  "pricing-tier.features",
  "pricing-tier.title",
  "step.description",
  "step.title",
  "testimonial.image",
  "testimonial.name",
  "testimonial.title",
  "testimonial.quote",
  "usp-list.title",
  "usp-list.image",
  "usp-list.usps",
  "usp.title",
  "usp.description",
  "usp.image",
  "blog-page.summary",
  "blog-page.socialCards",
  "faq.questionName",
  "faq.acceptedAnswerText",
];

export const repositoryName = "joinlifex";

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = "";

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  if (doc.type === "home-page") {
    return "/";
  }

  if (doc.type === "page") {
    return `/${doc.uid}`;
  }

  if (doc.type === "aarhus-page") {
    return `/aarhus/${doc.uid}`;
  }

  if (doc.type === "halle-page") {
    return `/halle/${doc.uid}`;
  }

  if (doc.type === "hamburg-page") {
    return `/hamburg/${doc.uid}`;
  }

  if (doc.type === "oslo-page") {
    return `/oslo/${doc.uid}`;
  }

  if (doc.type === "berlin-page") {
    return `/berlin/${doc.uid}`;
  }

  if (doc.type === "copenhagen-page") {
    return `/copenhagen/${doc.uid}`;
  }

  if (doc.type === "london-page") {
    return `/london/${doc.uid}`;
  }

  if (doc.type === "munich-page") {
    return `/munich/${doc.uid}`;
  }

  if (doc.type === "paris-page") {
    return `/paris/${doc.uid}`;
  }

  if (doc.type === "blog-page") {
    return `/blog/${doc.uid}`;
  }

  return "/";
};

export const PrismicLink = (type, element, content, children, index) => (
  <Link key={index} href={linkResolver(element.data)} as={linkResolver(element.data)}>
    <a href={linkResolver(element.data)}>{content}</a>
  </Link>
);

export const Router = {
  routes: [
    {
      type: "page",
      path: "/:uid",
    },
    {
      type: "home-page",
      path: "/",
    },
    {
      type: "aarhus-page",
      path: "/aarhus/:uid",
    },
    {
      type: "berlin-page",
      path: "/berlin/:uid",
    },
    {
      type: "copenhagen-page",
      path: "/copenhagen/:uid",
    },
    {
      type: "london-page",
      path: "/london/:uid",
    },
    {
      type: "halle-page",
      path: "/halle/:uid",
    },
    {
      type: "munich-page",
      path: "/munich/:uid",
    },
    {
      type: "oslo-page",
      path: "/oslo/:uid",
    },
    {
      type: "paris-page",
      path: "/paris/:uid",
    },
    {
      type: "blog-page",
      path: "/blog/:uid",
    },
    {
      type: "hamburg-page",
      path: "/hamburg/:uid",
    },
  ],
  href: (type) => {
    const route = Router.routes.find((r) => r.type === type);
    return route && route.href;
  },
};

export function createClient(config = {}) {
  const client = prismic.createClient(repositoryName, {
    routes: Router.routes,
    accessToken: process.env["PRISMIC_ACCESS_TOKEN"],
  });

  enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
}

export const client = createClient();
