import type { ComponentSingleStyleConfig, SystemStyleObject } from "@chakra-ui/react";

const baseStyle = {
  borderRadius: "base",
  fontWeight: "medium",
  _disabled: {
    bg: "#E9E8E7",
    color: "#BDBBB7",
    cursor: "not-allowed",
    boxShadow: "none",
    opacity: 1,
  },
  _hover: {
    _disabled: {
      bg: "#E9E8E7",
    },
    textDecoration: "none",
  },
};

function variantPrimary(): SystemStyleObject {
  const { bg = "almond", color = "white", hoverBg = "#BE642F", activeBg = "#BE642F" } = {};

  return {
    bg,
    color,
    _hover: { bg: hoverBg },
    _active: { bg: activeBg },
  };
}

function variantSecondary(): SystemStyleObject {
  const { bg = "cream", color = "almond", hoverBg = "#EDE8E2", activeBg = "#EDE8E2" } = {};

  return {
    bg,
    color,
    _hover: { bg: hoverBg },
    _active: { bg: activeBg },
  };
}

function variantTertiary(): SystemStyleObject {
  const { bg = "#FFFFFF", color = "almond", hoverBg = "#F4F4F3", activeBg = "#F4F4F3" } = {};

  return {
    bg,
    color,
    _hover: { bg: hoverBg },
    _active: { bg: activeBg },
  };
}

function variantTransparent(): SystemStyleObject {
  const { bg = "transparent", color = "white" } = {};

  return {
    bg,
    color,
  };
}

const variants = {
  primary: variantPrimary,
  secondary: variantSecondary,
  tertiary: variantTertiary,
  transparent: variantTransparent,
};

const defaultProps = {
  variant: "primary",
  size: "md",
  colorScheme: "orange",
};

export const Button: ComponentSingleStyleConfig = {
  baseStyle,
  defaultProps,
  variants,
};
