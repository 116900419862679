const baseStyle = {
  field: {
    boxShadow: "sm",
  },
};

const defaultProps = {
  focusBorderColor: "almond",
};

export const Select = {
  baseStyle,
  defaultProps,
};
