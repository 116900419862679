
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { Head } from "@/components/Head";
import { useHubspotTracking } from "@/hooks/useHubspotTracking";
import { theme } from "@/theme";
import { MenuData } from "@/types/menu-data";
import { ChakraProvider } from "@chakra-ui/react";
import { PrismicPreview } from "@prismicio/next";
import { PrismicProvider } from "@prismicio/react";
import type { PrismicDocument } from "@prismicio/types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "focus-visible/dist/focus-visible";
import App, { type AppContext, type AppProps } from "next/app";
import Link from "next/link";
import { useRouter } from "next/router";
import Script from "next/script";
import { parseCookies, setCookie } from "nookies";
import { client, linkResolver, repositoryName } from "prismic-configuration";
import * as React from "react";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import type { Page } from "../types/page";
dayjs.extend(utc);
// TODO: https://react-query.tanstack.com/guides/ssr
const queryCache = new QueryCache();
const queryClient = new QueryClient({
    queryCache,
    defaultOptions: {
        queries: {
            staleTime: Number.POSITIVE_INFINITY,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    },
});
type Properties = AppProps & {
    Component: Page<unknown>;
    props?: {
        banners: PrismicDocument[];
        navigationMenu: PrismicDocument;
    };
};
function MyApp({ Component, pageProps, props }: Properties) {
    const router = useRouter();
    const { setPathPageView } = useHubspotTracking();
    React.useEffect(() => {
        localStorage.setItem("chakra-ui-color-mode", "light");
    }, []);
    React.useEffect(() => {
        const handleRouteChange = (url: string) => {
            setPathPageView(url);
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);
    const cookies = parseCookies();
    const iubendaCookie = cookies["_iub_cs-90056981"];
    React.useEffect(() => {
        if (iubendaCookie) {
            const parsedCookie = JSON.parse(iubendaCookie);
            // User has the old cookie without per purpose consent
            if (parsedCookie["consent"]) {
                setCookie(null, "_iub_cs-90056981", "deleted", {
                    maxAge: -1,
                    path: "/",
                    secure: process.env.NODE_ENV === "production",
                    domain: ".joinlifex.com",
                });
                setCookie(null, "_iub_cs-90056981-granular", "deleted", {
                    maxAge: -1,
                    path: "/",
                    secure: process.env.NODE_ENV === "production",
                    domain: ".joinlifex.com",
                });
                setCookie(null, "euconsent-v2", "deleted", {
                    maxAge: -1,
                    path: "/",
                    secure: process.env.NODE_ENV === "production",
                    domain: ".joinlifex.com",
                });
            }
        }
    }, [iubendaCookie]);
    const getLayout = Component.getLayout ?? ((page) => page);
    return (<PrismicProvider linkResolver={linkResolver} internalLinkComponent={({ href, children, ...props }) => (<Link href={href} {...props}>
          {children}
        </Link>)}>
      <PrismicPreview repositoryName={repositoryName}>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme} resetCSS>
            <Head />
            <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG_ID}`} strategy="afterInteractive"/>
            <Script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" strategy="afterInteractive"/>
            <Script src="//js.hs-scripts.com/5105035.js" strategy="afterInteractive"/>
            <Script src="//cdn.iubenda.com/cs/iubenda_cs.js" strategy="afterInteractive"/>
            <Script id="iubenda" strategy="afterInteractive">
              {`
                  var _iub = _iub || [];
                  _iub.csConfiguration = {
                    "priorConsent": true,
                    "enableCMP": true,
                    "lang": "en",
                    "siteId": 1177215,
                    "cookiePolicyId": 90056981,
                    "countryDetection": true,
                    "perPurposeConsent": true,
                    "purposes": "1,4,5",
                    "consentOnContinuedBrowsing": false,
                    "invalidateConsentBefore": "2020-08-01",
                    "banner":  { 
                      "slideDown": false,
                      "position": "bottom",
                      "customizeButtonDisplay": true,
                    },
                  };
                `}
            </Script>
            <Script id="google-tag-manager" strategy="afterInteractive">
              {`
                  // Initialise the data layer for Google Tag Manager
                  window.dataLayer = window.dataLayer || [];
                  function gtag() {
                    dataLayer.push(arguments);
                  }

                  // Default consent mode is "denied" for both ads and analytics, but delay for 2 seconds until the Iubenda is loaded
                  gtag("consent", "default", {
                    ad_storage: "denied",
                    analytics_storage: "denied",
                    wait_for_update: 2000,
                  });

                  // Improve ad click measurement quality (optional)
                  gtag('set', 'url_passthrough', true);

                  // Further redact your ads data (optional)
                  gtag("set", "ads_data_redaction", true);

                  // Google Tag Manager
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTAG_ID}');
                `}
            </Script>
            {getLayout(<Component {...pageProps}/>, props?.navigationMenu.data as MenuData, props?.banners)}
            <ReactQueryDevtools initialIsOpen={true}/>
          </ChakraProvider>
        </QueryClientProvider>
      </PrismicPreview>
    </PrismicProvider>);
}
MyApp.getInitialProps = async (appContext: AppContext) => {
    const { router } = appContext;
    let navigationMenu: PrismicDocument<Record<string, any>, string, string>;
    let banners: PrismicDocument[];
    try {
        navigationMenu = (await client.getSingle("navigation-menu" as any, { lang: router.locale })) || {};
    }
    catch {
        navigationMenu = (await client.getSingle("navigation-menu" as any, { lang: "en-gb" })) || {};
    }
    try {
        banners = (await client.getByType("ctabanner", { lang: router.locale })).results || [];
    }
    catch {
        try {
            banners = (await client.getByType("ctabanner", { lang: "en-gb" })).results || [];
        }
        catch {
            const appProps = await App.getInitialProps(appContext);
            return {
                ...appProps,
                props: {
                    navigationMenu,
                },
            };
        }
    }
    const appProps = await App.getInitialProps(appContext);
    return {
        ...appProps,
        props: {
            banners,
            navigationMenu,
        },
    };
};
// `next-translate-plugin` doesn't properly detect that _app.tx is using getInitialProps and instead tries to load
// getServerSideProps, which causes the navigation menu & banner not to load -> footer and header do not load.
// We can create a workaround where we wrap it with a no-op HoC in order to force getInitialProps:
const forceNextTranslateGetInitialProps = (page: any) => {
    if (!page.getInitialProps) {
        throw new Error("This HOC should only be used on pages with getInitialProps");
    }
    return page;
};
const __Next_Translate__Page__1927af6f2e5__ = forceNextTranslateGetInitialProps(MyApp);

    export default __appWithI18n(__Next_Translate__Page__1927af6f2e5__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      
    });
  