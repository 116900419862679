import type { ComponentMultiStyleConfig, SystemStyleObject } from "@chakra-ui/react";

const parts = ["section", "card"];

const baseStyle: SystemStyleObject = {
  section: {
    pt: { base: 24, md: 36 },
    pb: { base: 24, md: 36 },
    overflow: "hidden",
  },
};

function variantWhite() {
  return {
    section: {
      bg: "#FFFFFF",
    },
    card: {
      bg: "cream",
    },
  };
}

function variantCream() {
  return {
    section: {
      bg: "cream",
    },
    card: {
      bg: "#FFFFFF",
    },
  };
}

function variantBlack() {
  return {
    section: {
      bg: "gray.900",
      color: "#FFFFFF",
    },
    card: {
      bg: "#FFFFFF",
    },
  };
}

function variantGreen() {
  return {
    section: {
      bg: "olive",
      color: "#FFFFFF",
    },
    card: {
      bg: "#FFFFFF",
    },
  };
}

const variants = {
  white: variantWhite,
  cream: variantCream,
  black: variantBlack,
  green: variantGreen,
};

const defaultProps = {
  variant: "white",
};

export const Section: ComponentMultiStyleConfig = { parts, baseStyle, defaultProps, variants };
