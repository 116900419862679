import { colors } from "./colors";
import { breakpoints } from "./breakpoints";
import { shadows } from "./shadows";
import { typography } from "./typography";

export const foundations = {
  colors,
  breakpoints,
  shadows,
  ...typography,
};
