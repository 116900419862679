type MethodParam =
  | "setPath"
  | "trackPageView"
  | "identify"
  | "trackEvent"
  | "revokeCookieConsent"
  | "addPrivacyConsentListener"
  | "doNotTrack"
  | "addIdentityListener"
  | "setContentType"
  | "refreshPageHandlers";

type SetTrackEventProps = {
  eventId: string;
  value?: number | string;
};

type PushParam = [MethodParam, (string | Record<string, unknown>)?];

declare global {
  interface Window {
    _hsq: { push: (callParam: PushParam) => void };
  }
}

type UseHubspotTrackingProps = {
  setContentType: (contentType: string) => void;
  setPathPageView: (path: string) => void;
  setTrackPageView: () => void;
  setIdentity: (email: string, customPropertities?: Record<string, any>) => void;
  setTrackEvent: ({ eventId, value }: SetTrackEventProps) => void;
};

export const useHubspotTracking = (): UseHubspotTrackingProps => {
  const _hsq = typeof window !== "undefined" && window._hsq ? window._hsq : undefined;

  const setContentType = (contentType: string): void => {
    _hsq?.push(["setContentType", contentType]);
  };

  const setTrackPageView = () => {
    _hsq?.push(["trackPageView"]);
  };

  const setPathPageView = (path: string): void => {
    _hsq?.push(["setPath", path]);
    setTrackPageView();
  };

  const setIdentity = (email: string, customPropertities?: Record<string, any>) => {
    _hsq?.push([
      "identify",
      {
        email,
        ...customPropertities,
      },
    ]);
  };

  const setTrackEvent = ({ eventId, value }: SetTrackEventProps) => {
    _hsq?.push([
      "trackEvent",
      {
        id: eventId,
        value,
      },
    ]);
  };

  return {
    setContentType,
    setPathPageView,
    setTrackPageView,
    setIdentity,
    setTrackEvent,
  };
};
