import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleFunction } from "@chakra-ui/styled-system";
import { getColor, mode } from "@chakra-ui/theme-tools";

const baseStyle = {
  field: {
    border: "1px solid",
    borderColor: "gray.400",
    boxShadow: "sm",
    _placeholder: {
      color: "gray.500",
    },
  },
};

const variantErik: PartsStyleFunction<typeof parts> = (props) => {
  const { theme } = props;

  return {
    field: {
      border: "1px solid",
      borderColor: "gray.400",
      bg: "inherit",
      boxShadow: "sm",
      _placeholder: {
        color: "gray.500",
      },
      _hover: {
        borderColor: mode("gray.300", "whiteAlpha.400")(props),
      },
      _readOnly: {
        boxShadow: "none !important",
        userSelect: "all",
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
      _invalid: {
        borderColor: getColor(theme, "almond"),
        boxShadow: `0 0 0 1px ${getColor(theme, "almond")}`,
      },
      _focus: {
        zIndex: 1,
        borderColor: getColor(theme, "almond"),
        boxShadow: `0 0 0 1px ${getColor(theme, "almond")}`,
      },
    },
    addon: {
      border: "1px solid",
      borderColor: mode("inherit", "whiteAlpha.50")(props),
      bg: mode("gray.100", "whiteAlpha.300")(props),
    },
  };
};

const variants = {
  erik: variantErik,
};

const defaultProps = {
  focusBorderColor: "almond",
  variant: "erik",
};

export const Input = {
  baseStyle,
  defaultProps,
  variants,
};
