module.exports = {
  locales: ["en-gb", "da-dk", "de-de", "fr-fr"],
  defaultLocale: "en-gb",
  localeDetection: false,
  pages: {
    "*": ["common"],
    "/404": ["404"],
    "/[uid]/signup": ["signup"],
    "/[uid]/subscribe": ["subscribe"],
    "/[uid]/homes/[apartment]/signup": ["signup"],
    "/[uid]/homes": ["homes"],
    "/[uid]/buildings/[building]": ["homes"],
    "/[uid]/homes/[apartment]": ["apartment"],
    "/[uid]/homes/[apartment]/[room]": ["signup"],
    "/blog/neighbourhood-guides": ["blog"],
    "/blog/move-in-guides": ["blog"],
    "/blog/articles": ["blog"],
  },
  loadLocaleFrom: async (locale, namespace) => {
    return import(`./src/locales/${locale}/${namespace}.json`).then((r) => r.default);
  },
};
