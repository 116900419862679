import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import { components } from "./components";
import { foundations } from "./foundations";
import { withProse } from "@nikolovlazar/chakra-ui-prose";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const overrides = {
  config,
  components,
  ...foundations,
};

export const theme = extendTheme(
  {
    styles: {
      global: {
        html: {
          scrollBehavior: "smooth",
        },
        ".pswp__img": {
          objectFit: "contain",
        },
      },
    },
  },
  overrides,
  withProse({
    baseStyle: {
      h1: {
        fontFamily: "Inter",
        fontSize: "xl",
        fontWeight: "medium",
        "&:first-of-type": {
          marginTop: 0,
        },
      },
      h2: {
        fontFamily: "Inter",
        fontSize: "xl",
        fontWeight: "medium",
        "&:first-of-type": {
          marginTop: 0,
        },
      },
      h3: {
        fontFamily: "Inter",
        fontSize: "xl",
        fontWeight: "medium",
        "&:first-of-type": {
          marginTop: 0,
        },
      },
    },
  }),
);
