function variantBadge() {
  return {
    tab: {
      borderRadius: "4px",
      fontWeight: "medium",
      color: "gray.600",
      bg: "white",
      px: 3,
      py: 1.5,
      fontSize: "sm",
      _selected: {
        color: "gray.900",
        bg: "gray.200",
      },
    },
    tabpanel: { px: 0, py: { base: 4, md: 10 } },
  };
}

const variants = {
  badge: variantBadge,
};

const defaultProps = {
  variant: "badge",
};

export const Tabs = {
  defaultProps,
  variants,
};
