import type { SystemStyleObject } from "@chakra-ui/react";

const baseStyle: SystemStyleObject = {
  h: "72px",
  w: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  py: 4,
  px: { base: 4, md: 10, xl: 20 },
  zIndex: 2,
};

function variantPrimary() {
  const { bg = "white", color = "gray.900" } = {};

  return {
    bg,
    color,
  };
}

function variantTransparent() {
  const { bg = "transparent", color = "white" } = {};

  return {
    bg,
    color,
  };
}

const variants = {
  primary: variantPrimary,
  transparent: variantTransparent,
};

export const Header = { baseStyle, variants };
