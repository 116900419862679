import type { NextSeoProps } from "next-seo";
import { DefaultSeo } from "next-seo";
import useTranslation from "next-translate/useTranslation";
import NextHead from "next/head";

const useSeo = (): NextSeoProps => {
  const { t, lang } = useTranslation("common");

  return {
    description: t("seo.description"),
    defaultTitle: t("seo.title"),
    titleTemplate: "%s | LifeX",
    canonical: "https://www.joinlifex.com/",
    openGraph: {
      type: "website",
      locale: lang,
      url: "https://www.joinlifex.com/",
      site_name: "LifeX",
      title: t("seo.title"),
      description: t("seo.description"),
      images: [{ url: "/og.jpg", alt: "LifeX", width: 1200, height: 630 }],
    },
    twitter: {
      site: "https://www.joinlifex.com/",
      cardType: "summary_large_image",
    },
    additionalLinkTags: [{ rel: "icon", href: "https://www.joinlifex.com/android-icon-192x192.png" }],
  };
};

export const Head = () => {
  const SEO = useSeo();

  return (
    <>
      <DefaultSeo {...SEO} />
      <NextHead>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
      </NextHead>
    </>
  );
};
