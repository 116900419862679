import { Badge } from "./Badge";
import { Button } from "./Button";
import { Card } from "./Card";
import { DropdownButton } from "./DropdownButton";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Input } from "./Input";
import { Section } from "./Section";
import { Select } from "./Select";
import { Step } from "./Step";
import { Tabs } from "./Tabs";
import { Textarea } from "./Textarea";

export const components = {
  Badge,
  Button,
  Card,
  DropdownButton,
  Header,
  Input,
  Footer,
  Section,
  Select,
  Step,
  Tabs,
  Textarea,
};
