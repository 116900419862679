import type { ComponentSingleStyleConfig, SystemStyleObject } from "@chakra-ui/react";

const baseStyle = {
  borderRadius: "base",
  fontWeight: "medium",
  display: "flex",
  alignItems: "center",
  px: 2,
  py: "1px",
  maxWidth: "max-content",
  textTransform: "unset",
  fontSize: "sm",
  lineHeight: 1.6,
};

function variantSuccess(): SystemStyleObject {
  const { bg = "green.600", color = "white" } = {};

  return {
    bg,
    color,
  };
}

function variantWarning(): SystemStyleObject {
  const { bg = "orange.600", color = "white" } = {};

  return {
    bg,
    color,
  };
}

function variantError(): SystemStyleObject {
  const { bg = "red.600", color = "white" } = {};

  return {
    bg,
    color,
  };
}

const variants = {
  success: variantSuccess,
  error: variantError,
  warning: variantWarning,
};

const defaultProps = {
  variant: "success",
};

export const Badge: ComponentSingleStyleConfig = {
  baseStyle,
  defaultProps,
  variants,
};
