import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

const parts = ["badge", "label"];

const baseStyle = {
  badge: {
    borderRadius: "base",
    fontWeight: "medium",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 8,
    minHeight: 8,
    width: 8,
    minWidth: 8,
    borderWidth: "1px",
    borderStyle: "solid",
    mr: 4,
  },
};

function variantCompleted() {
  const { bg = "gray.500", color = "white", borderColor = "gray.300" } = {};

  return {
    badge: { bg, color, borderColor },
    label: { color: "gray.500" },
  };
}

function variantStarted() {
  const { bg = "vanilla", color = "almond", borderColor = "rgba(0, 0, 0, 0.08)" } = {};

  return {
    badge: { bg, color, borderColor },
    label: { fontWeight: "medium" },
  };
}

function variantPending() {
  const { bg = "white", color = "almond", borderColor = "gray.100" } = {};

  return {
    badge: { bg, color, borderColor },
  };
}

const variants = {
  completed: variantCompleted,
  started: variantStarted,
  pending: variantPending,
};

const defaultProps = {
  variant: "completed",
};

export const Step: ComponentMultiStyleConfig = {
  baseStyle,
  defaultProps,
  variants,
  parts,
};
